import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./services/i18n";
import { socket, WebSocketProvider } from "./context/WebSocketContext";
import { Provider } from "react-redux";
import { store } from "./services/store/store";

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<WebSocketProvider value={socket}>
					<Provider store={store}>
						<App />
					</Provider>
				</WebSocketProvider>
			</Suspense>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
